import React from 'react'
import RazorpayButtoon from '../Components/RazorpayButton'
const Payment = () => {
  return (
    <>
      <RazorpayButtoon/>
    </>
  )
}

export default Payment
